<template>
  <div>
    <c-search-box @enter="getList" v-if="!searchParam.innerFlag">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam2.plantCd" @input="getList"/>
        </div>
      </template>
    </c-search-box>
    <!-- 응급상황 대응 목록 -->
    <c-table
      ref="table"
      title="응급상황 대응 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="bookPopup" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'eme-contact-manage',
  props: {
    searchParam: {
      type: Object,
      default: () => ({
        plantCd: null,
        innerFlag: '',
      }),
    },
    tabParam: {
      type: Object,
      default: () => ({
        dataTypeCd: '1',
        title: '비상연락체계도',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            type: "link",
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            // 최초등록자
            label: '최초등록자',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'regUserDeptName',
            field: 'regUserDeptName',
            // 최초등록부서
            label: '최초등록부서',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'regDtStrTime',
            field: 'regDtStrTime',
            // 최초등록일시
            label: '최초등록일시',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'chgUserName',
            field: 'chgUserName',
            // 최근수정자
            label: '최근수정자',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'chgUserDeptName',
            field: 'chgUserDeptName',
            // 최근수정부서
            label: '최근수정부서',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'chgDtStrTime',
            field: 'chgDtStrTime',
            // 최근수정일시
            label: '최근수정일시',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam2: {
        plantCd: null,
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.eme.contact.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      if (this.searchParam.innerFlag == 'Y') {
        this.searchParam2.plantCd = this.searchParam.plantCd;
      }
      this.$http.param = this.searchParam2;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.bookPopup(row);
    },
    bookPopup(result) {
      this.popupOptions.target = () => import(`${"./EmeContactDetail.vue"}`);
      this.popupOptions.title = '응급상황 대응 상세'; // 응급상황 대응 상세
      this.popupOptions.param = {
        emeConcactId: result ? result.emeConcactId : '',
      };
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
